<template>
  <div class="my-second help bgcolor">
      <Head :title="$t('mine.help_center')" :show="true" ></Head>
      <div class="common-box help-box">
          <div class="help-list">
                <van-cell-group :title="$t('mine.common_problem')" >
                    <van-cell  
                        :to="{name:'mine-help-detail',query:{name:l.title,num:l.num,index:i}}" 
                        v-for="(l,i) in user.helpcenter" 
                        :key="i" 
                        :title="i+1+'.'+l.title"  is-link   />
                </van-cell-group>
          </div>
      </div>
  </div>
</template>

<script>
import {myinfo} from "@/common"
export default {
    mixins:[myinfo],
    data(){
        return {
            list:[
                {
                    title: this.$t('help.one'),
                    num:1,
                },
                {
                    title: this.$t('help.two'),
                    num:2,
                },
                {
                    title: this.$t('help.three'),
                    num:3,
                },
                {
                    title: this.$t('help.four'),
                    num:4,
                },
                {
                    title: this.$t('help.five'),
                    num:5,
                }          
            ]
        }
    }
}
</script>

